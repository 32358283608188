<template>
  <div>
    <form-section :title="$t('courierSet.additionalData')">
      <v-row>
        <v-col>
          <text-field
            v-model="getParcel.content"
            :title=" $t('courierSet.content')"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getParcel.customerData1"
            :title=" $t('courierSet.customerData1')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <text-field
            v-model="getParcel.customerData2"
            :title=" $t('courierSet.customerData2')"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getParcel.customerData3"
            :title=" $t('courierSet.customerData3')"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getParcel() {
      return this.getConfigurationSet().dpdDefaultValues.parcel;
    },
  },
};
</script>

<style scoped>
</style>
